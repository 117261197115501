html{
  scroll-behavior: smooth;
}

body{
  width: 100%;
  background: black;
  color: white;

  &.no-scroll{
    overflow: hidden;
  }

}

.content{
  min-height: 150vh;
  overflow: hidden;
  width: 100%;
}


