@import url('https://fonts.googleapis.com/css2?family=Secular+One:wght@300&family=Secular+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap');


@font-face {
  font-family: Didot;
  src: url("../fonts/Didot Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Didot;
  src: url("../fonts/Didot LT Std Italic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}


@font-face {
  font-family: Inter;
  src: url("../fonts/Inter-ExtraLightItalic.otf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

.interH1Light{
  font-family: Inter, sans-serif;
  font-size: 96px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -1.5px;
}

.interH1Bold{
  font-family: Inter, sans-serif;
  font-size: 96px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -1.5px;
}

.interH2Light{
  font-family: Inter, sans-serif;
  font-size: 64px;
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.5px;
}

.interH2Bold{
  font-family: Inter, sans-serif;
  font-size: 64px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.interH3Light{
  font-family: Inter, sans-serif;
  font-size: 51px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.05em;
}

.interH3Bold{
  font-family: Inter, sans-serif;
  font-size: 51px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.interH4Light{
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.25px;

  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.interH4Bold{
  font-family: Inter, sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.25px;
}

.interH5Light{
  font-family: Inter, sans-serif;
  font-size: 25px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: .05em;

  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.5px;
  }
}

.interH5Bold{
  font-family: Inter, sans-serif;
  font-size: 25px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.interH6Light{
  font-family: Inter, sans-serif;
  font-size: 21px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.interH6Bold{
  font-family: Inter, sans-serif;
  font-size: 21px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.15px;

  @media (max-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: 400;
  }
}

.interSubtitle1{
  font-family: Inter, sans-serif;
  font-size: 17px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.interSubtitle2{
  font-family: Inter, sans-serif;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.interBody1{
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.interBody2{
  font-family: Inter, sans-serif;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.interButton{
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.15px;
}

.interCaption{
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.secularH1{
  font-family: Secular One, sans-serif;
  font-size: 96px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: -1.5px;
}

.secularH2{
  font-family: Secular One, sans-serif;
  font-size: 64px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: -0.5px;
}

.secularH3{
  font-family: Secular One, sans-serif;
  font-size: 51px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.05em;
}


.secularH4{
  font-family: Secular One, sans-serif;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.25px;
}

.secularH5{
  font-family: Secular One, sans-serif;
  font-size: 25px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.05em;

  @media (max-width: 768px) {
    font-size: 17px;
  }
}

.secularH6{
  font-family: Secular One, sans-serif;
  font-size: 21px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;

  @media (max-width: 768px) {
    font-size: 15px;
    letter-spacing: 0.1px;
  }
}

.secularSubtitle1{
  font-family: Secular One, sans-serif;
  font-size: 17px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;

  @media (max-width: 768px) {
    font-size: 15px;
    letter-spacing: 0.1px;
  }
}

.secularSubtitle2{
  font-family: Secular One, sans-serif;
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.1px;
}

.secularButton{
  font-family: Secular One, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.15px;
}

.secularCaption{
  font-family: Secular One, sans-serif;
  font-size: 12px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.didotH4{
  font-family: Didot, serif;
  color: white;
  letter-spacing: -0.5px;
  font-size: 64px;
  line-height: 100%;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 36px;
  }
}