@import "src/global/colors";

.wrap {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: center;


  background: black;

  z-index: 150000;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }


  .layout {

    height: 200px;
    position: relative;

    @media (max-width: 768px) {
      height: 130px;
    }

    div{
      overflow: hidden;
      margin-bottom: 8px;
    }


    .img1 {
      height: 53.7%;
    }

    .img2 {
      height: 32.5%;
    }

    .img3 {
      height: 14.1%;
    }

    img{
      height: 100%;
    }
  }
}