@import "src/global/colors";

.wrap {
  background: black;
  position: relative;

  .imgLogo{
    width: 100%;
    height: 100%;

  }

  .logoWrap{
    overflow: hidden;
    width: 100%;
    position: relative;
    margin-bottom: 178px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .logoWrapM{
     .logoLine{
       padding-right: 75px;
     }

    @media (min-width: 768px) {
      display: none;
    }
  }

  .desc {
    padding-top: 104px;
    width: 100%;
    margin-bottom: 101px;

    &:before, &:after {
      height: 2px;
      width: 50px;
      background: white;
      content: '';
      position: absolute;
      right: 0;

      @media (max-width: 768px) {
        width: 25px;
      }
    }

    p {
      font-family: Inter, sans-serif;
      font-weight: 200;
      font-style: italic;
      font-size: 64px;
      color: white;
      text-transform: uppercase;
      letter-spacing: .05em;

      @media (max-width: 768px) {
        font-size: 24px;
        max-width: calc(100% - 41px);
      }

      strong {
        font-style: normal;
        font-weight: 500;
        cursor: all-scroll;

        img{
          opacity: 0;
          transition:  opacity .3s;
          position: fixed;
          pointer-events: none;
          max-width: 30vw;
          max-height: 60vh;

        }

        &:hover{
          color: $gradient;

          img{
            opacity: 1;
          }
        }
      }
    }
  }
}