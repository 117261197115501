@import "src/global/colors";

.wrap{
  padding: 32px 0 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: black;
  position: relative;

  .copyr{
    position: absolute;
    bottom: 18px;
    left: calc(50% - 119px);
    font-size: 16px;

    @media (max-width: 768px) {
      position: relative;
      left: 0;
      bottom: 0;
      order: 4;
      margin-top: 16px;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .copyright{
    color: white;
    flex-shrink: 0;
    font-size: 14px;

    span{
      margin: 0 12px;
    }
  }

  .line{
    width: 100%;
    height: 2px;
    background: white;
    margin: 0 32px;

    @media (max-width: 768px) {
      margin: 24px 0;
    }
  }

  .links{
    display: flex;
    align-items: center;
    flex-shrink: 0;


    a{
      cursor: pointer;
      color: white;
      &:not(:last-child){
        margin-right: 16px;
      }
    }
  }
}