@import "src/global/colors";

.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 178px;

  @media (max-width: 768px) {
    padding-top: 88px;
  }

  .hideOnPhone{
    @media (max-width: 768px) {
      display: none;
    }
  }

  .showOnPhone{
    display: none;
    @media (max-width: 768px) {
      display: flex;
      margin-bottom: 32px;
    }
  }

  .animtext{
    overflow: hidden;
  }

  .imgReveal{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
  }

  .block {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column-reverse;
      grid-gap: 0;
      margin-top: 0;
    }

    form {
      .fields {
        display: flex;
        flex-direction: column;
        height: 340px;

        justify-content: space-between;

        @media (max-width: 768px) {
          height: 100%;
        }

        .inputs {
          display: flex;
          flex-direction: column;
        }
      }

      input, textarea {
        padding: 4px;
        border: none;
        border-bottom: 2px solid white;
        background: none;
        color: white;
        outline: none;
        resize: none;

        @media (max-width: 768px) {
          font-size: 17px;
        }

      }
    }

    input {
      margin-bottom: 40px;

      @media (max-width: 768px) {
        margin-bottom: 24px;
      }
    }

    textarea{
      @media (max-width: 768px) {
        height: 108px;

      }
    }

    button {
      display: flex;
      align-items: center;
      border: 2px solid white;
      padding: 32px 64px;
      border-radius: 100px;
      overflow: hidden;
      cursor: pointer;
      transition: .3s;
      position: relative;
      background: none;
      margin-top: 64px;

      svg {
        transition: .3s;

        path {
          transition: .3s;
        }
      }

      &:before {
        position: absolute;
        content: '';
        background: $gradient;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        transition: .3s;
        z-index: -1;
      }

      &:hover {
        &:before {
          opacity: 1;
        }

        p {
          -webkit-text-fill-color: black;
          -moz-text-fill-color: black;
        }

        svg {
          transform: translateX(5px);
        }

        path {
          fill: black;
          stroke: black;
        }
      }

      p {
        background-image: $gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        transition: .3s;

        margin-right: 16px;
      }
    }

    .image {
      height: 340px;
      object-fit: cover;


      @media (max-width: 768px) {
        height: 65vw;
        width: 100%;
        margin-bottom: 88px;
      }
    }

    .imageWrap {

      @media (max-width: 768px) {
        margin-right: -24px;
        margin-left: -24px;
      }
      .pseudoWrap {
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;

        &:before, &:after {
          height: 2px;
          width: 50px;
          background: white;
          content: '';
          position: absolute;
          right: 0;

          @media (max-width: 768px) {
            display: none;
          }

        }
        &:after{
          bottom: 0;
        }
      }
    }
  }
}