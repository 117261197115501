
.wrap{
  display: flex;
  flex-direction: column;

  h4{
    margin-bottom: 64px;
  }

  .picReveal{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
  }

  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;


    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .projectWrap{
      display: flex;
      flex-direction: column;
      padding: 32px;


      @media (max-width: 768px) {
        padding: 0;
        margin-right: -24px;
        margin-left: -24px;
      }

      h5{
        margin-top: 32px;
        margin-bottom: 16px;


        @media (max-width: 768px) {
          margin-top: 16px;
        }
      }


      @media (max-width: 768px) {
        h5, h6{
          margin-left: 24px;
          margin-right: 24px;
        }
      }

      img{
        height: 480px;
        object-fit: cover;
        box-sizing: border-box;
        width: 100%;


        @media (max-width: 768px) {
          height: 64vw;
        }
      }

    }
  }
}