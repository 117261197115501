.cursor{
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.33);
  z-index: 100000;
  position: fixed;
  pointer-events: none;
  mix-blend-mode: difference;
  opacity: 0;
  left: calc(50% - 32px);
  top: calc(50% - 32px);

  @media (max-width: 768px) {
    display: none;

  }

  svg{
    height: 100%;
    width: 100%;
  }
}
