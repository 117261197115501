
.icon{
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  width: 21px;
  height: 16px;
  display: none;

  @media (max-width: 768px) {
    display: flex;
  }

  .line1, .line2 span, .line3{
    transition: .5s;
  }


  span{
    height: 2px;
    width: 100%;
    background: white;
    border-radius: 4px;
  }

  .line2{
    position: relative;
    height: 2px;

    span{
      position: absolute;
      top: 0;

    }
  }

  &.active{

    .line1, .line3{
      opacity: 0;
    }

    .line2 span{
      transform: rotate(135deg);

      &:first-child{
        transform: rotate(-135deg);
      }
    }
  }
}

.wrap{
  background: black;
  position: fixed;
  width: 100%;
  right: 0;
  top: 64px;
  bottom: 0;
  left: 100%;
  transition: .3s;

  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    display: flex;
  }

  &.active{
    left: 0;
  }

  .navs{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 64px;

    .navLink{
      padding: 8px 16px;
      text-transform: uppercase;
      text-align: center;
      font-size: 17px;
      letter-spacing: 0.15px;
    }
  }
}
