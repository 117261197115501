.wrap {
  margin-top: 178px;

  h1 {
    @media (max-width: 768px) {
      margin: 0 24px;
    }
  }

  @media (max-width: 768px) {
    margin: 0;
    margin-top: 88px;
  }

  .picReveal {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
  }

  .imgWrap {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    margin-top: 64px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      margin-top: 32px;
    }
  }


  .project {
    padding: 32px;
    display: flex;
    flex-direction: column;

    .textWrap {
      @media (max-width: 768px) {
        padding: 0 24px;
      }

      p{
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0;
    }

    h5 {
      margin-top: 32px;
      margin-bottom: 16px;

      @media (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }
}