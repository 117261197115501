@import "src/global/colors";

.wrap{
  padding: 32px 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: black;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 10000;

  @media (max-width: 1100px) {
    padding: 32px 32px;
  }

  @media (max-width: 768px) {
    padding: 16px 24px;
  }

  .logo{
    flex-shrink: 0;
    height: 32px;
    width: 64px;

    svg{
      width: 100%;
      height: 100%;
    }
  }

  .line{
    width: 100%;
    height: 2px;
    background: white;
    margin: 0 32px;
  }

  .navs{
    display: flex;
    align-items: center;
    flex-shrink: 0;

    @media (max-width: 768px) {
      display: none;
    }

    .navLink{
      padding: 8px 16px;
      color: white;
      text-transform: uppercase;
      transition: .3s;
      border-radius: 26px;
      border: 2px solid transparent;
      box-sizing: border-box;

      &:hover{
        border-color: white;

      }

      &:not(:last-child){
        margin-right: 16px;
      }
    }
  }
}