body, html{
  margin: 0;
  padding: 0;

}

a, p, h1,h2,h3,h4,h5,h6{
  text-decoration: none;
  margin: 0;
  padding: 0;
  color: white;
}

.marquee-container{
  overflow: hidden;
}

.leaflet-container{
  height: 100%;
  width: 100%;
}