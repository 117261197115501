@import "src/global/colors";


.wrap {
  display: flex;
  flex-direction: column;



  .headerWrapper{
    overflow: hidden;
    margin-top: 358px;
    margin-bottom: 178px;

    @media (max-width: 768px) {
      margin-top: 104px;
      margin-bottom: 88px;
    }

    h1 {
      font-family: Didot, serif;
      font-size: 96px;
      letter-spacing: -1.5px;

      font-weight: 400;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 36px;
      }
    }
  }

  .picReveal{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
  }

  .mainPhoto {
    width: 100%;
    height: 100vh;
    object-fit: cover;

    @media (max-width: 768px) {
      height: 64vw;
    }
  }

  .infoWrap {
    display: flex;

    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 104px;

    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 88px;
    }

    .textWrap {
      width: 750px;
      flex-direction: column;
      display: flex;
      margin-right: 32px;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 88px;
      }

      .textContainer {
        display: flex;
        flex-direction: column;
        padding: 64px 0;

        @media (max-width: 768px) {
          padding: 32px 0;
        }

        h5 {
          margin-top: 56px;
          margin-bottom: 32px;

          font-family: Didot, serif;
          font-style: italic;
          font-size: 51px;
          line-height: 100%;
          font-weight: 400;

          @media (max-width: 768px) {
            font-size: 36px;
            margin-top: 24px;
            margin-bottom: 16px;
          }
        }
      }


      &:before, &:after {
        height: 2px;
        width: 50px;
        background: white;
        content: '';

        @media (max-width: 768px) {
          width: 25px;
        }
      }
    }

    .specWrap {
      width: 380px;
      position: relative;
      flex-direction: column;
      display: flex;

      @media (max-width: 768px) {
        width: 100%;
      }

      .specContainer {
        flex-direction: column;
        display: flex;
        margin: 64px 0;

        @media (max-width: 768px) {
          margin: 32px 0;

          h6{
            font-size: 21px;
          }
        }

        .col{
          display: flex;
          flex-direction: column;
          margin-top: 56px;

          @media (max-width: 768px) {
            margin-top: 24px;
          }
        }

        .specBlock{
          display: flex;
          flex-direction: column;


          h6{
            margin-bottom: 16px;
            font-family: Didot, serif;
            font-style: italic;
            font-size: 21px;
            letter-spacing: .15px;
            line-height: 100%;
            font-weight: 400;
          }

          &:not(:last-child){
            margin-bottom: 32px;
          }
        }


      }

      &:before, &:after {
        height: 2px;
        width: 50px;
        background: white;
        content: '';

        @media (max-width: 768px) {
          width: 25px;
        }
      }
    }
  }

  .photos{
    display: flex;
    flex-direction: column;

    margin-top: 178px;

    @media (max-width: 768px) {
      margin-top: 88px;
      margin-right: -24px;
      margin-left: -24px;
    }

    .photoGrid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
      box-sizing: border-box;
      margin-bottom: 178px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        margin-bottom: 32px;
        grid-gap: 32px;
      }

      .gridPic{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .bigPicture{
      width: 100%;

      img{
        width: 100%;
      }
    }
  }

  .mapWrap{
    height: 750px;
    max-height: 750px;
    display: flex;
    margin-top: 178px;
    position: relative;


    @media (max-width: 768px) {
      height: 100%;
      margin-top: 88px;
      flex-direction: column;
    }

    .map{
      width: 100%;


      @media (max-width: 768px) {
        height: 64vw;
      }
    }

    .addressWrap{
      display: flex;
      flex-direction: column;
      margin-left: 32px;
      margin-right: 32px;


      @media (max-width: 768px) {
        margin-left: 24px;
        margin-right: 24px;
        padding: 32px 0;
      }


      .address{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom:  64px;
        max-width: 380px;


        @media (max-width: 768px) {
          margin: 32px 0;
        }
      }

      &:before, &:after {
        height: 2px;
        width: 50px;
        background: white;
        content: '';


        @media (max-width: 768px) {
          width: 25px;
        }
      }

      h4{
        font-family: Didot, serif;
        font-weight: 400;
        font-size: 36px;
        line-height: 100%;
        letter-spacing: 0.25px;
        margin-bottom: 24px;


        @media (max-width: 768px) {
          font-size: 21px;
          margin-bottom: 16px;
        }
      }
    }
  }

  .otherProjectsWrap{
    display: flex;
    flex-direction: column;

    margin-top: 178px;


    @media (max-width: 768px) {
      margin-top: 88px;
    }
  }
}