.side-margins{
  margin: 0 150px;

  @media (max-width: 1100px) {
    margin: 0 32px;
  }

  @media (max-width: 768px) {
    margin: 0 24px;
  }
}

.text-block{
  max-width: 1200px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}