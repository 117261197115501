@import "src/global/colors";

.wrap{
  display: flex;
  flex-direction: column;
  margin-top: 178px;
  margin-bottom: 140px;

  @media (max-width: 768px) {
    margin-top: 88px;
    margin-bottom: 88px;
  }

  .boldLink a{
    font-weight: bold !important;
  }

  .wrapLines{
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;
    width: 100%;

    @media (max-width: 768px) {
      grid-gap: 24px;
      margin-top: 32px;
    }
  }

  .contactsWrap{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    grid-gap: 32px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }

    .contactWrap{
      display: flex;
      flex-direction: column;
    }


    .textSmall{
      font-family: Didot, serif;
      font-size: 25px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: .05em;
      margin-top: 24px;

      @media (max-width: 768px) {
        margin-top: 16px;
        font-size: 21px;
      }
    }

    .link{
      font-family: Didot, serif;
      font-size: 21px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: .05em;
      margin-top: 50px;
      color: white;

      @media (max-width: 768px) {
        margin-top: 0;
      }
    }
  }
}