@import "src/global/colors";

.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 178px;

  @media (max-width: 768px) {
    padding-top: 88px;
  }


  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 64px 32px;
    margin-top: 64px;

    @media (max-width: 1400px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-gap: 32px;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .block {
      display: flex;
      flex-direction: column;



      h2 {
        margin-bottom: 32px;

        @media (max-width: 768px) {
          font-size: 51px;
          margin-bottom: 16px;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .animtext {
        overflow: hidden;
      }

      h5 {
        font-family: Didot, serif;
        font-weight: 400;
        font-size: 51px;
        line-height: 100%;
        min-height: 102px;
        padding-bottom: 24px;
        position: relative;
        margin-bottom: 24px;

        @media (max-width: 768px) {
          min-height: 0;
          font-size: 25px;
          margin-bottom: 16px;
        }

        &:after {
          height: 2px;
          width: 50px;
          background: white;
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;

          @media (max-width: 768px) {
            width: 25px;
          }
        }
      }
    }
  }
}