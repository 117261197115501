@import "src/global/colors";

.wrap {
  margin-top: 180px;
  position: relative;

  .anchor{

  }

  .overviewWrap {
    display: flex;
    flex-direction: column;
    position: relative;


    .title {
      margin-top: 64px;
      margin-bottom: 32px;
    }

    .text {
      color: white;
      width: 100%;

      @media (max-width: 768px) {
        max-width: calc(100% - 41px);
      }

      &:before, &:after {
        height: 2px;
        width: 50px;
        background: white;
        content: '';
        position: absolute;
        right: 0;

        @media (max-width: 768px) {
          width: 25px;
        }
      }
    }
  }


  .statsWrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
    align-items: center;

    margin-top: 178px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      margin-top: 88px;
    }

    .col1 {
      display: flex;
      flex-direction: column;

      p{
        color: white;
      }
      .counter {
        font-family: Didot, sans-serif;
        font-weight: 400;
        font-size: 270px;
        margin-bottom: 32px;
        color: white;
        line-height: 100%;


      }

      .info {
        margin-top: 24px;
      }
    }

    .col2 {
      display: flex;
      flex-direction: column;

      .stat{
        display: flex;
        flex-direction: column;

        &:not(:last-child){
          margin-bottom: 64px;
        }

        .counter {
          margin-bottom: 24px;
          @media (max-width: 768px) {
            font-size: 64px;
            letter-spacing: 0.5px;
          }

        }

        .title{
          font-family: Didot, serif;
          font-weight: 400;
          font-size: 36px;
          letter-spacing: 0.25px;
          line-height: 100%;
          color: white;

          @media (max-width: 768px) {
            font-size: 25px;
            letter-spacing: 0.5px;
            margin-bottom: 16px;
          }

          margin-bottom: 6px;
        }

        .info {

        }
      }
    }
  }

  .animOverflow{
    overflow: hidden;
  }
}