@import "src/global/colors";

.wrap {
  display: flex;
  flex-direction: column;
  padding-top: 178px;

  @media (max-width: 768px) {
    padding-top: 88px;
  }

  .line {
    height: 2px;
    right: 0;
    left: 0;
    background: white;
    box-sizing: border-box;

    @media (max-width: 768px) {
      box-sizing: content-box;
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .runningLine {
    padding: 79px 0;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 70px 0;
    }

    .textWrap{
      display: flex;
      align-items: center;

      .textBlock{
        display: flex;
        align-items: center;
        margin-right: 64px;
      }

      .cursive{
        font-family: Didot, serif;
        font-weight: 400;
        font-size: 96px;
        margin-left: 8px;
        background-image: $gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
      }
    }
  }

  .catWrap {

    .photoReveal{
      position: absolute;
      right: 0;
      top: 0;
      left: 0;
      bottom: 0;
      background: black;
    }


    a{
      display: flex;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &:not(:last-child) {
      margin-bottom: 178px;

      @media (max-width: 768px) {
        margin-bottom: 88px;
      }
    }

    &:nth-child(2) a {
      flex-direction: row-reverse;

      @media (max-width: 768px) {
        flex-direction: column;
      }

    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 380px;
      margin-left: 32px;
      margin-right: 32px;
      position: relative;

      @media (max-width: 768px) {
        margin: 0;
        margin-top: 24px;
        padding-top: 32px;
        width: 100%;
      }

      &:before {
        height: 2px;
        width: 50px;
        background: white;
        content: '';
        position: absolute;
        left: 0;
        top: 0;

        @media (max-width: 768px) {
          width: 25px;
        }
      }

      h6 {
        margin-top: 24px;
      }

      .devWrap {
        margin-top: 100px;
        position: relative;

        @media (max-width: 768px) {
          margin-top: 32px;
        }

        p {
          margin: 24px 0;
        }

        &:before, &:after {
          height: 2px;
          width: 50px;
          background: white;
          content: '';
          position: absolute;
          left: 0;

        }
      }
    }

    .photo {
      width: 100%;
      height: 700px;
      object-fit: cover;
      flex-shrink: 11;
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        height: 60vw;
      }
    }
  }
}